/**
 * Pavilion Genting Highlands
 * @author FOREFRONT International <support@forefront.com.my>
 */
import $ from 'jquery';
import 'bootstrap';
import 'slick-carousel';
import MoveTo from 'moveto';
import ScrollMonitor from 'scrollmonitor';
import * as Utils from './utils';
import Foresight from './vendor/foresight';

let foresight = null;

// Run entry function when DOM has fully loaded
window.addEventListener('DOMContentLoaded', main);

// Run loader animation
if (Utils.isPage('home')) {
  window.setTimeout(enableLoadingAnimation, 400);
}


/**
 * Entry function.
 */
function main() {
  // Run global functions
  enableSmoothAnchorScrolling();
  enableMobileNavigation();
  enableLanguage();
  enableEventTracking();

  // Run homepage functions
  if (Utils.isPage('home')) {
    enableFixedNavigation();
    enableFeaturesCarousel();
    trackFormAbandonment();
  }
}


/**
 * Enable mobile navigation.
 */
function enableMobileNavigation() {
  const button = document.getElementById('nav-mobile-button');
  const container = document.getElementById('nav-mobile-menu-container');
  const menu = document.getElementById('nav-mobile-menu');

  $(button).on('click', function() {
    $(container).hasClass('is-active')
      ? hideMenu()
      : showMenu()
  });

  $(menu).on('click', hideMenu)

  function showMenu() {
    $(container).addClass('is-active');
    $(button).addClass('is-active');
  }

  function hideMenu() {
    $(container).removeClass('is-active');
    $(button).removeClass('is-active');
  }
}


/**
 * Enable fixed navigation.
 */
function enableFixedNavigation() {
  const hero = document.querySelector(`.js-page-hero`);
  const nav = document.getElementById('fixed-navigation');
  if (hero && nav) {
    const watcher = ScrollMonitor.create(hero);
    watcher.exitViewport(() => nav.classList.add('is-active'));
    watcher.enterViewport(() => nav.classList.remove('is-active'));
  }
}


/**
 * Activate smooth scrolling for anchor elements tagged with the
 * [data-smooth-scroll] attribute.
 */
function enableSmoothAnchorScrolling() {
  const moveTo = new MoveTo();
  $('[data-smooth-scroll]').on('click', function(e) {
    const url = new URL($(this)[0].href);
    const targetElement = $(url.hash)[0];
    if (targetElement) {
      e.preventDefault();
      moveTo.move(targetElement);
    }
  });
}


/**
 * Enable GA event tracking.
 */
function enableEventTracking() {
  foresight = new Foresight();
}


/**
 * Send an event to GA when a user fills in a form halfway then leaves the site.
 */
function trackFormAbandonment() {
  let isFormDirty = false;
  $('.registration-form input, .registration-form select, .registration-form textarea').change(() => {
    isFormDirty = true;
  });

  // Send GA event before browser closes tab
  window.addEventListener('beforeunload', () => {
    if (isFormDirty) {
      foresight.send({ category: 'General', action: 'Form Abandon' });
    }
  }, false);
}


/**
 * Enables the loading animation.
 */
function enableLoadingAnimation() {
  onBegin();
  window.scrollTo(0, 0);

  function onBegin() {
    $('#masthead').addClass('is-loading');
    $('body').addClass('is-loading');

    if (document.readyState === 'complete') {
      window.setTimeout(onComplete, 500);
    } else {
      window.addEventListener('load', onComplete);
    }
  }
  
  function onComplete() {
    $('#masthead').removeClass('is-loading');
    $('#masthead').addClass('is-loaded');
    setTimeout(() => {
      $('body').addClass('is-loaded');
      $('body').removeClass('is-loading');
      $('#home-main-backgrounds').addClass('is-loaded');
      $('#home-hero-footer').addClass('is-loaded');
    }, 2000);
    // setTimeout(() => {
    //   $('#modal-season').modal('show');
    // }, 3000);
  }
}


/**
 * Enables the features carousel sliders and animations.
 */
function enableFeaturesCarousel() {
  $('#features-carousel').slick({
    appendDots: '#features-indicators',
    arrows: true,
    asNavFor: '#features-background-carousel',
    autoplaySpeed: 10000,
    dots: true,
    draggable: false,
    fade: true,
    nextArrow: document.getElementById('features-carousel-control-next'),
    prevArrow: document.getElementById('features-carousel-control-prev'),
    speed: 1200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          cssEase: 'ease',
        }
      }
    ]
  });

  // Start autoplay when in view
  const element = document.getElementById('features-carousel');
  if (element) {
    const watcher = ScrollMonitor.create(element);
    watcher.enterViewport(() => $('#features-carousel').slick('slickPlay'));
    watcher.exitViewport(() => $('#features-carousel').slick('slickPause'));
  }

  $('#features-background-carousel').slick({
    speed: 1200,
    arrows: false,
    fade: true,
    asNavFor: '#features-carousel',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
          draggable: true,
        }
      }
    ]
  });

  $('#features-carousel').on('afterChange', function(event, slick, currentSlide, nextSlide) {
    $('#features-carousel .feature-title-line').removeClass('animate');
    $('#features-carousel .slick-current .feature-title-line').addClass('animate');
  });
}


/**
 * Enable language.
 */
function enableLanguage() {
  const button = document.getElementsByClassName("btn-language");
  
  $(button).on('click', function(){
    $(button).removeClass("active");
    $(this).addClass("active");
  })
}